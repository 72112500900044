import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  format,
  addMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isToday,
  isSameMonth,
  isSameDay,
  addWeeks,
} from "date-fns";
import MultiHelper from "./MultiHelper";
import SingleHelper from "./SingleHelper";
import { useRef, useState } from "react";
import MultiSelectCalendar from "./MultiSelectCalendar";
import SingleSelectCalendar from "./SingleSelectCalendar";

export default function Container({ activeTab }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [multipleTimeSlots, setMulitpleTimeSlots] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const handleClick = () => {
    console.log(`${selectedDate} ${timeSlots}`);
  };

  const handleMultipleClick = () => {
    const result = [];
    selectedDates.forEach((date) => {
      multipleTimeSlots.forEach((slot) => {
        result.push({ date, slot });
      });
    });
    console.log(result);
  };

  return (
    <div className="flex gap-5">
      {/* Helper component inside a card on the left side */}
      <div className="flex-1">
        <div className="mt-4 divide-y divide-gray-100 text-sm/6 lg:col-span-9 xl:col-span-9">
          {activeTab === "Single Booking" ? (
            <SingleHelper timeSlots={timeSlots} setTimeSlots={setTimeSlots} />
          ) : (
            <MultiHelper
              timeSlots={multipleTimeSlots}
              setTimeSlots={setMulitpleTimeSlots}
            />
          )}
        </div>
      </div>

      {/* Calendar component inside a card on the right side */}
      {activeTab === "Single Booking" ? (
        <SingleSelectCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleClick={handleClick}
        />
      ) : (
        <MultiSelectCalendar
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          handleClick={handleMultipleClick}
        />
      )}
    </div>
  );
}
