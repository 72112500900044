import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";

export default function AddDoctor({ isOpen, onClose }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50 ">
      {/* Transition for modal sliding in from the right */}
      <Transition
        show={isOpen}
        enter="transition-transform duration-500 ease-in-out"
        enterFrom="translate-x-full" // Starts off screen to the right
        enterTo="translate-x-0" // Ends at the original position
        leave="transition-transform duration-500 ease-in-out"
        leaveFrom="translate-x-0" // Starts from the original position
        leaveTo="translate-x-full" // Ends off screen to the right
      >
        <div className="bg-white shadow-lg rounded-l-lg w-[500px] relative pt-5">
          <div className="px-4 py-6">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold text-gray-900">Add Doctor</h2>
              <button onClick={onClose} className="text-gray-500">
                X
              </button>
            </div>
            <form action="#" method="POST" className="space-y-6 mt-4">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-900"
                >
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  className="mt-2 w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-900"
                >
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  required
                  className="mt-2 w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
              </div>
              <div>
                <label
                  htmlFor="dob"
                  className="block text-sm font-medium text-gray-900"
                >
                  Date of Birth
                </label>
                <div className="relative mt-2">
                  <input
                    id="dob"
                    name="dob"
                    type="text"
                    value={
                      selectedDate ? selectedDate.toLocaleDateString() : ""
                    }
                    readOnly
                    className="w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 pr-10"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-3 flex items-center text-gray-500 hover:text-gray-700"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    <CalendarIcon className="w-5 h-5" aria-hidden="true" />
                  </button>

                  {showCalendar && (
                    <div className="absolute top-0 right-0 mt-12 z-10 bg-white shadow-lg rounded-lg border border-gray-300">
                      <DatePicker
                        selected={selectedDate}
                        onChange={(date) => {
                          setSelectedDate(date);
                          setShowCalendar(false);
                        }}
                        inline
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="speciality"
                  className="block text-sm font-medium text-gray-900"
                >
                  Specialty
                </label>
                <input
                  id="speciality"
                  name="speciality"
                  type="text"
                  required
                  className="mt-2 w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
              </div>
              <div>
                <label
                  htmlFor="qualification"
                  className="block text-sm font-medium text-gray-900"
                >
                  Qualification
                </label>
                <input
                  id="qualification"
                  name="qualification"
                  type="text"
                  required
                  className="mt-2 w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
              </div>
              <div>
                <label
                  htmlFor="contact"
                  className="block text-sm font-medium text-gray-900"
                >
                  Contact Number
                </label>
                <input
                  id="contact"
                  name="contact"
                  type="text"
                  required
                  className="mt-2 w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-900"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="mt-2 w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600"
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full mt-6 py-2 bg-red-500 hover:bg-red-700 text-white font-semibold rounded-md transition duration-200 ease-in-out transform hover:scale-105 active:scale-100"
                >
                  Add Doctor
                </button>
              </div>
            </form>
          </div>
        </div>
      </Transition>
    </div>
  );
}
