import { useEffect, useState } from "react";
import { getRequest } from "../../../api/axios";
import getAllHospitals from "../../../api/hospitalAxios";
import getViewDoctors from "../../../api/viewDoctor";
import { useNavigate } from "react-router-dom";

export default function HospitalTableContent({ title }) {
  const [hospitals, setHospitals] = useState([]);
  const [doctorByHospital, setDoctorByHospital] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAllHospitals();
        setHospitals(response.data.body);
        console.log(response.data.body);
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };
    getData();
  }, []);

  const handleViewDoctor = (id) => {
    // Navigate to the doctors list page with the hospitalId query parameter
    navigate(`/hmate/doctorslist/?hospitalId=${encodeURIComponent(id)}`);
  };

  return (
    <div className="my-10 p-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md">
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-xl font-bold text-gray-900">{title}</h3>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 -mt-3">
              {/* <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Title
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead> */}
              <tbody className="divide-y divide-gray-200">
                {hospitals.length === 0
                  ? "No Hospitals available for now"
                  : hospitals.map((hospital) => (
                      <tr key={hospital.licenseKey}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {hospital.hospitalName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {hospital.specialty}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {hospital.location}
                        </td>
                        <td className="whitespace-nowrap text-sm text-gray-500">
                          <button className="border content-center px-3 py-2 hover:bg-gray-800 hover:text-white">
                            View Appointments
                          </button>
                        </td>
                        <td className="whitespace-nowrap text-sm text-gray-500">
                          <button
                            className="border content-center px-3 py-2 hover:bg-gray-800 hover:text-white"
                            onClick={() =>
                              handleViewDoctor(hospital.hospitalID)
                            }
                          >
                            View Doctors
                          </button>
                        </td>
                        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Edit<span className="sr-only">, {person.name}</span>
                        </a>
                      </td> */}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
