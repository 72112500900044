import { getRequest } from "./axios.js";

const getViewDoctors = async (id) => {
  const url = `/api/doctor/getAllDoctorsByHospitalId/${id}`;
  console.log(id);
  const response = await getRequest(url);
  return response;
};

export default getViewDoctors;
