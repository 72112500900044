import getAllAppointments from "../../../api/appointmentAxios";
import { useState, useEffect } from "react";

export default function AppointmentTableContent({ title }) {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getAllAppointments();
        setAppointments(response.data.body);
        console.log(response.data.body);
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };
    getData();
  }, []);

  return (
    <div className="my-10 p-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-md">
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-xl font-bold text-gray-900">{title}</h3>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {appointments.length === 0 ? (
              <div className="min-w-full divide-y divide-gray-300">
                Loading...
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Doctor
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Hospital
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Time
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {appointments.map((appointment) => (
                    <tr key={appointment.appointmentId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {appointment.doctor.firstName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {appointment.hospital.hospitalName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {appointment.appointmentDate}
                      </td>
                      <td className="whitespace-nowrap text-sm text-gray-500">
                        {appointment.appointmentTime}
                      </td>
                      <td className="whitespace-nowrap text-sm text-gray-500">
                        <button className="border content-center px-3 py-2 hover:bg-gray-800 hover:text-white">
                          View Appointment
                        </button>
                      </td>
                      <td className="whitespace-nowrap text-sm">
                        <button className="border content-center px-3 py-2 bg-red-400 text-white hover:bg-gray-800 hover:text-white">
                          Cancel Appointment
                        </button>
                      </td>
                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit<span className="sr-only">, {person.name}</span>
                      </a>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
