import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const Paginator = ({
  rowFrom,
  rowTo,
  totalRows,
  currentPage,
  rowsPerPage,
  handlePageChange,
}) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const maxButtons = 5;
  const getPageNumbers = () => {
    let strtPage;
    let endPage;

    // if ((currentPage - 1) % maxButtons === 0) {
    //   strtPage = currentPage - maxButtons;
    //   endPage = currentPage + 1;
    // }

    if (currentPage <= Math.ceil(maxButtons / 2)) {
      strtPage = 1;
      endPage = Math.min(maxButtons, totalPages);
    } else if (currentPage + Math.ceil(maxButtons / 2) >= totalPages) {
      strtPage = Math.max(1, totalPages - maxButtons + 1);
      endPage = totalPages;
    } else {
      strtPage = currentPage - Math.floor(maxButtons / 2);
      endPage = currentPage + Math.floor(maxButtons / 2);
    }

    let pages = [];
    for (let i = strtPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const onChange = (page) => {
    if (page < 1 || page > totalPages) return;
    handlePageChange(page);
  };
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          href="#"
          onClick={() => onChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          href="#"
          onClick={() => onChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{rowFrom}</span> to{" "}
            <span className="font-medium">{rowTo}</span> of{" "}
            <span className="font-medium">{totalRows}</span> results
          </p>
        </div>
        <div>
          <nav
            aria-label="Pagination"
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          >
            <button
              onClick={() => onChange(currentPage - 1)}
              disabled={currentPage === 1}
              href="#"
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            {getPageNumbers().map((page, index) => {
              return (
                <button
                  aria-current="page"
                  onClick={() => onChange(page)}
                  key={index}
                  className={
                    (page === currentPage
                      ? "bg-indigo-600 text-white z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      : " text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0") +
                    "relative inline-flex items-center px-4 py-2 text-sm font-semibold "
                  }
                >
                  {page}
                </button>
              );
            })}
            <button
              onClick={() => onChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              href="#"
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
