import { createBrowserRouter } from "react-router-dom";
import Rootlayout from "../pages/Rootlayout";
import Dashboard from "../pages/Dashboard";
import Doctors from "../pages/Doctors";
import Booking from "../pages/Booking";
import DoctorsList from "../pages/DoctorsList";
import AddDoctor from "../pages/AddDoctor";
import Appointment from "../pages/Appointment";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Rootlayout />, // Root Layout with Navbar, etc.
    children: [
      { path: "", element: <Dashboard /> }, // Default route
      { path: "hmate/dashboard", element: <Dashboard /> },
      { path: "hmate/doctorslist", element: <DoctorsList /> },
      { path: "hmate/doctorslist/doctor/:doctorId", element: <Doctors /> },
      { path: "hmate/appointments", element: <Appointment /> },
      { path: "hmate/bookings", element: <Booking /> },
      { path: "hmate/addDoctor", element: <AddDoctor /> },
    ],
  },
]);

export default router;
