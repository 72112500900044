import axios from "axios";

const client = axios.create({
  baseURL: "https://mi-state.com/",
  withCredentials: false,
});

export const getRequest = async (url) => {
  const response = await client.get(url);
  return response;
};

export const postRequest = async (url, data) => {
  const response = await client.post(url, data);
  return response;
};

export const putRequest = async (url, data) => {
  const response = await client.put(url, data);
  return response;
};

export const deleteRequest = async (url, data) => {
  const response = await client.delete(url, data);
  return response;
};

export default client;
