import React, { useState, useRef } from "react";
import {
  format,
  isSameDay,
  isToday,
  isSameMonth,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MultiSelectCalendar({
  selectedDates,
  setSelectedDates,
  handleClick,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartDate, setDragStartDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const containerRef = useRef(null);

  const firstDayOfCurrentMonth = startOfMonth(currentMonth);
  const lastDayOfCurrentMonth = endOfMonth(currentMonth);
  const calendarInterval = {
    start: startOfWeek(firstDayOfCurrentMonth),
    end: endOfWeek(lastDayOfCurrentMonth),
  };

  const daysInCalendar = eachDayOfInterval(calendarInterval);

  const handleDateClick = (day) => {
    if (selectedDates.some((date) => isSameDay(date, day))) {
      setSelectedDates(selectedDates.filter((date) => !isSameDay(date, day)));
    } else {
      setSelectedDates([...selectedDates, day]);
    }
  };

  const handleMouseDown = (day) => {
    setIsDragging(true);
    setDragStartDate(day);
  };

  const handleMouseEnter = (day) => {
    if (isDragging && dragStartDate) {
      const dragRange = eachDayOfInterval({
        start: dragStartDate < day ? dragStartDate : day,
        end: dragStartDate > day ? dragStartDate : day,
      });
      setSelectedDates((prevDates) => [
        ...new Set([...prevDates, ...dragRange]),
      ]);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStartDate(null);
  };

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => {
      const newDate = new Date(prevMonth);
      newDate.setMonth(prevMonth.getMonth() - 1);
      return newDate;
    });
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => {
      const newDate = new Date(prevMonth);
      newDate.setMonth(prevMonth.getMonth() + 1);
      return newDate;
    });
  };

  return (
    <div
      ref={containerRef}
      className="flex-1 bg-white shadow-lg rounded-lg p-6"
      onMouseLeave={handleMouseUp} // Stop dragging if the mouse leaves the calendar
    >
      <div className="flex items-center">
        <h2 className="flex-auto ml-4 text-sm font-semibold text-gray-900">
          {format(currentMonth, "MMMM yyyy")}
        </h2>
        <button
          type="button"
          onClick={handlePrevMonth}
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={handleNextMonth}
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      <div className="mt-10 grid grid-cols-7 text-center text-xs text-gray-800">
        <div>Sun</div>
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
      </div>

      <div className="mt-2 grid grid-cols-7 text-sm">
        {daysInCalendar.map((day, dayIdx) => {
          const isPrevOrNextMonth = !isSameMonth(day, currentMonth); // Check if the day is from the previous or next month
          const isTodayDay = isToday(day); // Check if the day is today
          const isBeforeToday = day < new Date(); // Check if the day is before today

          return (
            <div
              key={day.toISOString()}
              className={classNames(
                dayIdx >= 7 && "border-t border-gray-200",
                "py-2"
              )}
            >
              <button
                type="button"
                onMouseDown={() => handleMouseDown(day)}
                onMouseEnter={() => handleMouseEnter(day)}
                onMouseUp={handleMouseUp}
                onClick={() => handleDateClick(day)}
                className={classNames(
                  selectedDates.some((date) => isSameDay(date, day)) &&
                    "bg-rose-700 hover:bg-rose-700 text-white",
                  isTodayDay && "text-[#E63525]",
                  !isSameDay(day, selectedDates) &&
                    "hover:bg-rose-400 hover:text-white", /// Highlight today's date
                  isBeforeToday && "text-gray-400", // Grey out days before today
                  !isSameMonth(day, currentMonth) &&
                    !isBeforeToday &&
                    "text-gray-400", // Grey out previous month dates, excluding today
                  "mx-auto flex h-8 w-8 items-center justify-center rounded-full transition-transform duration-300 ease-in-out transform"
                )}
                style={{
                  transition: "box-shadow 0.1s ease, transform 0.1s ease",
                }}
                onFocus={(e) => {
                  e.target.classList.add("shadow-lg", "transform", "scale-105");
                }}
                onBlur={(e) => {
                  e.target.classList.remove(
                    "shadow-lg",
                    "transform",
                    "scale-105"
                  );
                }}
                disabled={isBeforeToday} // Disable dates before today
              >
                <time dateTime={day.toISOString()}>{format(day, "d")}</time>
              </button>
            </div>
          );
        })}
      </div>

      <button
        type="button"
        onClick={handleClick}
        disabled={selectedDates.length === 0}
        className={`mt-5 mx-auto w-full rounded-md ${
          selectedDates.length === 0
            ? "bg-gray-400 cursor-not-allowed"
            : "mt-8 w-full rounded-md bg-red-500 hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition duration-200 ease-in-out transform hover:scale-105 active:scale-100"
        } px-3 py-2 text-md font-semibold text-white shadow`}
      >
        Add event
      </button>
    </div>
  );
}
