import { ChevronDownIcon } from "@heroicons/react/16/solid";

const tabs = [
  { name: "Single Booking", href: "#", current: false },
  { name: "Multiple Booking", href: "#", current: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs({ activeTab, setActiveTab }) {
  return (
    <div>
      <div className="grid grid-cols-1 sm:hidden">
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          defaultValue={tabs.find((tab) => tab.current).name}
          aria-label="Select a tab"
          className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
        <ChevronDownIcon
          aria-hidden="true"
          className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
        />
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex">
            <div className="flex w-1/2 gap-x-4">
              {" "}
              {/* Added gap-x-4 for spacing */}
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  className={classNames(
                    tab.name === activeTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-center text-sm font-medium"
                  )}
                  onClick={() => setActiveTab(tab.name)}
                >
                  {tab.name}
                </button>
              ))}
            </div>
            <div className="w-1/2"></div>
          </nav>
        </div>
      </div>
    </div>
  );
}
