import { getRequest } from "./axios.js";

const getAllDoctors = async (rowsPerPage, currentPage) => {
  const url = `/api/doctor/getAllDoctors?limit=${rowsPerPage}&skip=${
    (currentPage - 1) * rowsPerPage
  }`;
  const response = await getRequest(url);
  return response;
};

export default getAllDoctors;
