import {
  BarsArrowUpIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import getAllDoctors from "../api/doctorsAxios";
// New function to fetch doctors by hospital ID
import AddDoctor from "./AddDoctor"; // Import the AddDoctorModal component
import getViewDoctors from "../api/viewDoctor";
import Paginator from "../components/Doctors/Paginator";

export default function DoctorsList() {
  const navigate = useNavigate();
  const location = useLocation(); // Use location to get query params
  const [doctors, setDoctors] = useState([]);
  const [showForm, setShowForm] = useState(false); // State to manage the modal visibility
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  // const [pageSize, setPageSize] = useState(10); // Number of doctors per page
  const [totalDoctors, setTotalDoctors] = useState(0); // Total number of doctors

  const [totalRows, setTotalRows] = useState(0);
  const rowsPerPage = 10;

  // Extract the hospitalId from query parameters using useLocation
  const queryParams = new URLSearchParams(location.search);
  const hospitalId = queryParams.get("hospitalId");
  useEffect(() => {
    console.log("this is id", hospitalId);
    const fetchDoctors = async () => {
      try {
        if (hospitalId) {
          // If hospitalId is present, fetch doctors based on the hospitalId
          console.log("Fetching doctors for Hospital ID:", hospitalId);
          const response = await getViewDoctors();
          setDoctors(response.data.body);
          setTotalDoctors(response.data.total); // Set total doctors count based on the hospital-specific data
        }
        if (hospitalId == null) {
          // If no hospitalId, fetch all doctors
          const response = await getAllDoctors(rowsPerPage, currentPage);
          setDoctors(response.data.body);
          setTotalDoctors(response.data.total); // Set total doctors count for all doctors
        }
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    };
    fetchDoctors();
  }, [hospitalId, currentPage]); // Dependency on hospitalId, currentPage, and pageSize

  // const totalPages = Math.ceil(totalDoctors / pageSize); // Calculate total number of pages

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
      <div className="border-b border-gray-200 p-4 px-8 pb-5 sm:flex sm:items-end sm:justify-between">
        <h3 className="text-xl font-semibold text-gray-900">Doctors</h3>

        <div className="mt-3 sm:ml-4 sm:mt-0">
          <label htmlFor="mobile-search-candidate" className="sr-only">
            Search
          </label>
          <div className="flex rounded-md">
            <button
              className="mr-4 bg-red-500 p-1 px-4 hover:bg-red-700 rounded-md text-white"
              onClick={() => setShowForm(true)} // Show the form when button is clicked
            >
              Add Doctor
            </button>
            <div className="relative grow focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="size-5 text-gray-400"
                />
              </div>
              <input
                id="mobile-search-candidate"
                name="mobile-search-candidate"
                type="text"
                placeholder="Search"
                className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
              />
              <input
                id="desktop-search-candidate"
                name="desktop-search-candidate"
                type="text"
                placeholder="Search candidates"
                className="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm/6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
              />
            </div>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <BarsArrowUpIcon
                aria-hidden="true"
                className="-ml-0.5 size-5 text-gray-400"
              />
              Sort
              <ChevronDownIcon
                aria-hidden="true"
                className="-mr-1 size-5 text-gray-400"
              />
            </button>
          </div>
        </div>
      </div>

      <ul className="divide-y ">
        {doctors.map((doctor) => (
          <li
            key={doctor.email}
            onClick={() => {
              navigate(`/hmate/doctorslist/doctor/${encodeURIComponent(doctor.doctorID)}`);
            }}
            className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 cursor-pointer"
          >
            <div className="flex min-w-0 gap-x-4">
              <svg
                fill="#000000"
                width="30"
                height="30"
                viewBox="-1.5 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6.53 8.098l.14-.012c.053-.006.101-.025.141-.053l-.001.001c.134.462.298.948.503 1.457.263.666.522 1.213.812 1.741l-.04-.08c-.024.364-.053.738-.091 1.1-.018.223-.062.431-.129.627l.005-.018c-.012.005-.029 2.08-.029 2.08.001 1.353.938 2.486 2.198 2.787l.02.004c.057-.145.195-.246.357-.246h.574c.161.002.299.102.356.243l.001.003c1.283-.302 2.224-1.435 2.229-2.789v-.001s-.035-2.066-.053-2.08c-.055-.175-.099-.381-.122-.593l-.001-.015c-.035-.364-.058-.729-.091-1.1.247-.446.506-.992.734-1.555l.038-.106c.205-.509.364-.994.503-1.457.039.028.087.047.139.053h.001l.141.012c.17.018.32-.122.334-.339l.152-1.931c0-.001 0-.002 0-.002 0-.163-.122-.297-.279-.317h-.002-.017c.039-.281.061-.605.061-.934 0-.718-.106-1.412-.303-2.065l.013.051c-.577-1.266-1.721-2.185-3.099-2.442l-.026-.004c-.296-.061-.641-.102-.993-.112h-.009-.012c-.359.007-.704.047-1.038.118l.036-.006c-1.402.264-2.544 1.183-3.114 2.419l-.011.027c-.186.6-.293 1.29-.293 2.004 0 .333.023.661.068.981l-.004-.037c-.159.018-.282.151-.282.313v.007l.152 1.931c.014.222.166.356.33.338z" />
                <path d="M21.416 20.878c-.07-3.04-.374-3.728-.538-4.194-.065-.187-.118-1.451-2.206-2.271-1.28-.504-2.932-.514-4.33-1.105v1.644c-.003 1.768-1.269 3.239-2.944 3.56l-.023.004c-.031.182-.187.318-.374.32h-.018v1.24c0 1.212.982 2.194 2.194 2.194s2.194-.982 2.194-2.194v-.866c-.608-.091-1.069-.609-1.069-1.235 0-.689.559-1.248 1.248-1.248s1.248.559 1.248 1.248c0 .546-.351 1.01-.839 1.18l-.009.003v.918.047c0 1.532-1.242 2.774-2.774 2.774s-2.774-1.242-2.774-2.774c0-.017 0-.033 0-.05v.002-1.251c-.178-.012-.322-.146-.35-.318v-.002c-1.69-.329-2.95-1.795-2.954-3.556v-1.657c-1.404.603-3.066.615-4.353 1.12-2.094.819-2.142 2.08-2.206 2.27-.16.468-.468 1.153-.538 4.195-.012.4 0 1.013 1.206 1.549 2.626 1.03 6.009 1.35 9.344 1.58h.32c3.342-.228 6.72-.547 9.344-1.58 1.201-.533 1.212-1.142 1.201-1.546zm-14.681-1.24h-1.246v1.251h-.89v-1.247h-1.246v-.89h1.246v-1.246h.89v1.246h1.246z" />
                <path d="M16.225 17.965v-.001c0-.372-.301-.673-.673-.673s-.673.301-.673.673.301.673.673.673c.371 0 .672-.301.673-.672z" />
              </svg>
              <div className="min-w-0 flex-auto">
                <p className="text-sm/6 font-semibold text-gray-900">
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {doctor.firstName + " " + doctor.lastName}
                </p>
                <p className="mt-1 flex text-xs/5 text-gray-500">
                  <span className="relative truncate hover:underline">
                    {doctor.email}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4">
              <div className="hidden sm:flex sm:flex-col sm:items-end">
                <p className="text-sm/6 text-gray-900">{doctor.specialty}</p>
              </div>
              <ChevronRightIcon
                aria-hidden="true"
                className="size-5 flex-none text-gray-400"
              />
            </div>
          </li>
        ))}
      </ul>

      <Paginator
        rowFrom={(currentPage - 1) * rowsPerPage + 1}
        rowTo={Math.min(totalRows, currentPage * rowsPerPage)}
        currentPage={currentPage}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        handlePageChange={handlePageChange}
      />

      {/* Conditionally Render Modal for Add Doctor Form */}
      {showForm && (
        <AddDoctor isOpen={showForm} onClose={() => setShowForm(false)} />
      )}
    </div>
  );
}
